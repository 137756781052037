// src/components/Resume.js

import React from 'react';
import './Resume.css';

// Import the resume PDF
const resumePdf = require('../assets/vainresume.pdf');

function Resume() {
  return (
    <section className="resume">
      <h2>Resume</h2>
      <p>
        Below is my resume. You can also <a href={resumePdf} target="_blank" rel="noopener noreferrer">download it here</a>.
      </p>
      <div className="resume-viewer">
        <iframe 
          src={resumePdf} 
          width="100%" 
          height="800px" 
          title="Resume"
          frameBorder="0"
        >
          This browser does not support PDFs. Please download the PDF to view it: <a href={resumePdf}>Download PDF</a>.
        </iframe>
      </div>
    </section>
  );
}

export default Resume;
